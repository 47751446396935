html,body{
  height:100%;
  background-color: #F5F8F9;
}

.flex-fill {
  flex:1;
}
.App {
  text-align: center;
  background-color: #F5F8F9;
  height: 100%;
}

#dropdownMenuLink {
  color: #fff;
}
.parent {
  padding-top: 30px;
  padding-bottom: 30px;
}
.info {
  padding-left: 10px
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.space {
  padding-top: 20px;
  padding-bottom: 30px;
}

.space2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*Button Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  color:#5A6268;
  border-color:#5A6268;
  background: #fff radial-gradient(circle, transparent 1%, #5A6268 1%) center/15000%;
}
.ripple:active {
  background-color: #5A6268;
  color:#fff;
  border-color:#5A6268;
  background-size: 100%;
  transition: background 0s;
}


@media only screen and (max-width: 576px) {
  .spacer {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.card_container{
  border: none;
}

.line {
  border-top:none;
  border-left: none;
  border-right: none;
  border-bottom:#737a7f solid 4px;
  
}
.line2 {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.fsze {
  font-size: large;
}

.comb {
  border-top:#737a7f solid 4px;
  padding-top:15px;
}
.para{ 
  padding-bottom: 20px;
  padding-top:20px;
}

.other {
  padding-top: 20px;
}

.others {
  padding-top: 20px;
}
.head {
  padding-bottom:25px;
  text-align: left;
  
}
.head2 {
  text-align: left;
}
.words {
  padding-left: 10px;
  padding-right:10px;
  text-align: justify;
}
.words > h2, h4, h5,h6 {
  text-align: center;
  
}
.final {
  text-align: center;
}
.pills {
  padding: 10px;
}
.popup {
  padding-top:20px;
}
.specify {
  background-color: #5A6268;
  color: #fff;
}
.dropdown-menu {         
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-menu.show {
  display:block;
  right:70px;
}

#disDd.dropdown-menu.show {
  display: block;
 
}
@media (min-width: 1200px) and (max-width: 2000px){
  #nation > .dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: 150px;
    right: 0px;
   } 
  #depDd > .dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: -90px;
    right: 0px;
  }
  
  #disDd >.dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: -400px;
    right: 0px;
  }
  #cob > .dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: 150px;
    right: 0px;
   } 
  
  #rgr > .dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: 150px;
    right: 0px;
   } 
}

@media (min-width: 700px) and (max-width: 1199px){
  .dropdown-menu.show {
    display:block;
    right: -15px;
    /* right:70px; */
  }
 
  #depDd > .dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: -90px;
    right: 0px;
  }
  
  #disDd >.dropdown-menu.show {
    display: inline-block;
    position: absolute;
    left: -400px;
    right: 0px;
  }
  
  #cob > .dropdown-menu.show {
    display: inline-block;
    position: absolute;
    /* left: 150px; */
    right: -60px;
   } 

}


.card-body {
  height: 100%;
}
@media (max-width: 699px){
  .dropdown-menu.show {
    display:block;
    /* right: -15px; */
    /* right:70px; */
  }
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.invalid-form-input {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
.hasErr {
  text-align: right;

}
.form-control:focus {
  border-color:#737a7f;
  box-shadow: 0 0 0 0 rgba(115, 122, 127)
}
.pointer {cursor: pointer;}